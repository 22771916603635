/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-06-21 11:09:14
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-25 19:02:43
 */
import { axios } from '@/utils/request'

export const listDealerCollectionStatistics = params => axios({
  url: '/api/dealer/dealer/dealerStatics/collectionStatisticsForCurrFirm',
  method: 'get',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})